import { Action0, Action1, RequestBody } from '@/utils/Types'
import { Address, UpdatedItem } from '@models/Index'
import { toast } from 'react-toastify'
import axios from './axios'

export const appUtils = {
  async fetcher(url: string, withCache?: boolean) {
    if (withCache) {
      const res = sessionStorage.getItem(url)
      if (res) {
        return JSON.parse(res)
      }
    }
    const res = await axios.get(url)
    if (res && res.data && res.data.code == '0') {
      const data = res.data.data
      if (withCache && data) {
        if (Array.isArray(data) && data.length == 0) {
          return data
        }
        sessionStorage.setItem(url, JSON.stringify(data))
      }
      return data
    }
    throw new Error('An error occurred while fetching the data.')
  },

  getErrorMsgByCode(code: string, errStr: {}, msg?: string) {
    if (errStr[code] !== undefined) {
      return errStr[code]
    } else {
      return msg && code > '500' ? msg : errStr['general_error']
    }
  },

  handleApiError(e, errStr: {}) {
    if (e?.response?.data?.code) {
      const resCode: string = e.response.data.code
      const errorData = e.response.data.data
      return this.getErrorMsgByCode(resCode, errStr, e.response?.data?.message).templated(errorData ? Object.values(errorData) : '0')
    } else {
      return this.getErrorMsgByCode('general_error', errStr)
    }
  },

  async postData(url: string, params: RequestBody, errStr: {}, toastError?: boolean, setLoading?: Action1<boolean>, onError?: Action1<string>) {
    let errorMessage = ''
    let errorCode = null
    if (setLoading) {
      setLoading(true)
    }
    const res = await axios
      .post(url, params)
      .then(res => {
        if (setLoading) {
          setLoading(false)
        }
        if (res && res.data && res.data.code != '0') {
          errorCode = res.data.code
          errorMessage = this.getErrorMsgByCode(res.data.code, errStr)
          return null
        }
        return res.data.data
      })
      .catch(e => {
        if (setLoading) {
          setLoading(false)
        }
        errorCode = e.response?.data?.code
        errorMessage = this.handleApiError(e, errStr)
      })
    if (errorMessage) {
      if (toastError) {
        toast.error(errorMessage, { toastId: errorCode })
      }
      if (onError) {
        onError(errorMessage)
      }
      return null
    }
    return res
  },

  addressFormat(address: Address) {
    return (
      this.getString(address.street_2, ', ') +
      this.getString(address.street, ', ') +
      this.getString(address.county, ', ') +
      this.getString(address.city, ', ') +
      this.getString(address.postcode, ', ') +
      this.getString(address.country)
    )
  },

  getString(str: string, sufx?: string) {
    return str ? str + (sufx ?? '') : ''
  },

  getItemsStr(itemNumber: number, cartStr: {}) {
    const itemStrCode = itemNumber > 1 ? ' items' : ' item'
    if (cartStr[itemStrCode] !== undefined) {
      return itemNumber + cartStr[itemStrCode]
    }
    return itemNumber + itemStrCode
  },

  isEmpty(obj: any) {
    if (!obj || obj.length == 0) {
      return true
    }
    switch (obj) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof obj == 'undefined':
        return true
      default:
        return false
    }
  },
  getInventoryAlert(updatedItems: UpdatedItem[], errStr: {}) {
    return updatedItems
      .map(item => {
        const str = item.limit ? errStr['limited_per_user'].templated(item.limit) : errStr['stock_remaining'].templated(item.qty)
        return '"' + item.item_name + '"' + str
      })
      .join('\n')
  },

  getDefaultCurrency() {
    return 'GBP' // TODO 根据 域名 不同
  },
  isFakeEmail(email: string) {
    return email && (email.endsWith('google.com') || email.endsWith('facebook.com') || email.endsWith('twitter.com') || email.endsWith('twitteroauth2'))
  },

  evaluateRemoteScript(url: string, onLoad?: Action0 | null, beforeLoading?: Action0) {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    if (onLoad) {
      script.onload = onLoad
    }
    if (beforeLoading) {
      beforeLoading()
    }
    document.body.appendChild(script)
  },
  getTopLevelDomain() {
    const hostname = window.location.hostname // 当前页面的主机名
    const parts = hostname.split('.')
    if (parts.length <= 2 || /^(localhost|[\d.]+)$/.test(hostname)) {
      return hostname
    }
    // 返回顶级域名（最后两部分，例如 example.com）
    return parts.slice(-2).join('.')
  },
}
